.bg-dark-black {
  background-color: #000 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.bg-ex-light {
  background: #f8f9fa;
}

.opt-btns::-webkit-scrollbar {
  height: 4px !important;
  /* height of horizontal scrollbar */
  width: 1px !important;
  /* width of vertical scrollbar */
  /* border: 1px solid #fff; */
}

.opt-btns::-webkit-scrollbar-track {
  background: #000 !important;
}

.opt-btns::-webkit-scrollbar-thumb {
  background: #e4e4e4 !important;
  border-radius: 10px;
}

html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

.btn-blue {
  color: white !important;
  background-color: #392f9c !important;
  border-color: #392f9c !important;
}

.btn-blue:hover {
  background-color: #171347 !important;
  border-color: #171347 !important;
}

.navbar#navbar-main {
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  background: white;
}

.cookie-modal {
  position: fixed !important;
  bottom: 20px !important;
  z-index: 270;
  overflow: hidden;
  outline: 0;
}

.modal.show {
  display: block !important;
}

.Landing .help-btn {
  position: fixed;
  padding-bottom: 17px;
  bottom: -14px;
  z-index: 998;
  right: 115px;
}

.Landing .radius-none {
  border-radius: 0 !important;
}

.Landing .w-200 {
  width: 200px !important;
}

.Landing .min-width-content {
  min-width: max-content !important;
}

.Landing .top-btn {
  position: fixed !important;
  bottom: 17px;
  z-index: 999;
  right: 40px;
  border-radius: 100px !important;
  width: 50px;
  height: 50px;
}

.Landing .btn-outline-primary {
  padding-top: 0.2rem !important;
}

.Landing .btn-outline-primary:hover {
  color: white !important;
}

img#navbar-logo,
img.navbar-logo {
  border-radius: 8px;
  width: 58px;
}

img.svg-inject.img-fluid {
  height: 42px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 31px;
}

.Landing .list-group-item.list-group-item-action.dropdown-toggle {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.Landing .dropdown-submenu > .dropdown-item::after,
.Landing .dropdown-submenu > .list-group-item::after {
  display: none;
}

.Landing button.btn.btn-link.h1 {
  font-size: x-large;
}

.Landing .billing-cycles-toggle > img {
  position: relative;
  top: -15px;
  right: -65px;
  width: 44px;
}

.tagDiv {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: fit-content !important;
  padding: 3px 19px !important;
  border-radius: 20px !important;
  position: absolute !important;
  border-top-width: 1px !important;
  border-color: #c83f46 !important;
}

.text {
  font-size: 12px !important;
  color: #b62d34 !important;
}

.icon-svg {
  height: 17px;
  width: 17px;
}

.logo-container {
  height: 150px;
  background: #d3313a;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container > .logo {
  width: 40px;
  height: 40px;
}

.logo-container > .logo-name {
  font-size: 22px;
}

.logo-name {
  font-weight: 600;
  color: #fff;
  margin-left: 10px;
}

.btn-container {
  height: auto;
  /* overflow: auto; */
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  padding: 40px 0;
}

.big-font {
  font-size: 24px !important;
  line-height: 120% !important;
  font-weight: 600;
  font-style: normal;
  font-family: "Internacional", "Helvetica", "Arial" !important;
}

.opt-btn {
  position: relative;
  margin-bottom: 20px;
  width: auto;
  display: inline;
  float: left;
  clear: left;
  padding: 7px 15px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 130%;
  text-align: center;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 20px;
  border: 1px solid #833c40;
  justify-content: center;
  width: 100%;
}

.opt-btns {
  list-style-type: none;
  padding-left: 0;
}

.opt-btn.active {
  background: rgba(236, 113, 113, 0.25);
  border: 1px solid #833c40;
}

.opt-btn .opt-pre-text {
  opacity: 0;
}

.opt-text {
  transition: all 0.5s;
}

.opt-btn.active .opt-pre-text {
  opacity: 1;
  transition: all 0.5s;
  transition-delay: 100ms;
}

.opt-btn .opt-text {
  margin-left: -34px;
}

.opt-btn.active .opt-text {
  margin-left: 0;
}

.wrapper {
  /* width: 270px; */
}

.tab-section {
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
}

.tabpanel {
  justify-content: center;
  align-items: center;
  transition: all 3s;
}

.img {
  margin: auto;
  float: left;
}

.img-border {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 8px;
}

.points {
  /* line-height: 300%; */
}

.img-width {
  width: 100%;
  height: auto;
  max-width: 500px !important;
}

.animation {
  /* animation: fadeInRight 8s forwards infinite; */
  /* transition: all 8s ease-in-out; */
  animation-name: change-letter;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

@keyframes change-letter {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }

  /* 40% {
    opacity: 1;
  } */
  /* 45% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 5;
  } */
}

@keyframes fadeInRight {
  0% {
    /* opacity: 0; */
    transform: translateX(300px);
  }

  20% {
    /* opacity: 1; */
    transform: translateX(0);
  }

  80% {
    opacity: 1;
  }
}

.title {
  overflow: hidden;
  margin-left: 10px;

  white-space: nowrap;
  /* Keeps the content on a single line */

  margin-right: auto;
  /* animation: typing 8s steps(40, end) infinite; */
  color: #c83f46 !important;
  /* font-size: 2.25rem !important; */
}

/* The typing effect */
@keyframes typing {
  0% {
    width: 0;
  }

  30% {
    width: 35%;
  }
}

@media only screen and (max-width: 700px) {
  #couponVerification.show .modal-content {
    padding: 2rem;
  }

  #couponVerification.show .modal-dialog {
    margin: auto;
  }

  #couponVerification.show {
    justify-content: center !important;
    display: flex !important;
  }

  .help-btn .btn-inner--text {
    display: none;
  }

  .tagDiv {
    padding: 0px 8px !important;
  }

  .text {
    font-size: 10px !important;
  }

  .icon-svg {
    height: 13px;
    width: 13px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  ul.opt-btns {
    display: flex;
    overflow: auto;
  }

  .big-font {
    font-size: 18px !important;
    line-height: 120% !important;
    font-weight: 500;
  }

  .wrapper {
    /* width: 330px; */
    width: 100%;
    /* padding-right: 50px;
    padding-left: 50px; */
  }

  .img-width {
    width: 100%;
    height: auto;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 420px) {
  .Landing .help-btn {
    right: auto !important;
  }

  .tagDiv {
    padding: 0px 8px !important;
  }

  .text {
    font-size: 10px !important;
  }

  .icon-svg {
    height: 13px;
    width: 13px;
  }

  .img-width {
    width: 100%;
    height: auto;
    max-width: 100% !important;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    padding: 30px 20px;
  }
}

@media only screen and (min-width: 715px) {
  .btn-container {
    display: flex;
    justify-content: center;
    padding: 30px 20px;
  }
}

.verify_input {
  margin-bottom: 10px;
}

.verify_input .ui-input__input {
  width: 304px;
  padding: 12px;
  border: 1px solid #d3d3d3;
}

.coupon-modal-body {
  justify-content: center;
  display: grid;
  padding: 45px 0 65px 0 !important;
}

.dropdown-menu.dropdown-menu-md.p-0 p {
  white-space: normal;
}

section.Landing.Register button#submit-btn {
  width: 72%;
}

/* ribbons style */
.wrap {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
}

.wrap:before,
.wrap:after {
  content: "";
  position: absolute;
}

.wrap:before {
  width: 40px;
  height: 8px;
  right: 100px;
  background: #540b0e;
  border-radius: 8px 8px 0px 0px;
}

.wrap:after {
  width: 8px;
  height: 40px;
  right: 0px;
  top: 100px;
  background: #540b0e;
  border-radius: 0px 8px 8px 0px;
}

.ribbon6 {
  width: 200px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 2;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px dashed;
  box-shadow: 0 0 0 3px #d33139, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
  background: #d33139;
  text-align: center;
  color: white;
}

/* discount ribbon */
h6.rbn2 {
  font-weight: normal;
  position: relative;
  background: #6264a7;
  width: 44%;
  color: #ffffff;
  text-align: center;
  padding: 6px 10px;
  margin: 30px auto 10px;
  text-transform: uppercase;
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

h6.rbn2:before,
h6.rbn2:after {
  content: "";
  position: absolute;
  top: -6px;
  border: 15px solid #6264a7;
}

h6.rbn2:before {
  left: -33px;
  border-right-width: 18px;
  border-left-color: transparent;
}

h6.rbn2:after {
  right: -33px;
  border-left-width: 18px;
  border-right-color: transparent;
}

h6.rbn2 span:before,
h6.rbn2 span:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: #171347 transparent transparent transparent;
  top: -6px;
  transform: rotate(180deg);
}

h6.rbn2 span:before {
  left: 0;
  border-width: 6px 0 0 6px;
}

h6.rbn2 span:after {
  right: 0;
  border-width: 6px 6px 0 0;
}

body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar {
  height: 1rem;
}

* ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  background-clip: content-box;
  border-color: transparent;
  border-width: 0.25rem;
  border-style: solid;
  border-radius: 0.5625rem;
  min-height: 60px;
}

* ::-webkit-scrollbar-track {
  background: transparent;
  /* border: 1px solid rgb(240, 240, 240); */
}

.videoScreen .videoScreenElem {
  height: 2.5rem;
  display: flex;
  align-items: center;
}

.w-3 {
  width: 0.75rem;
}

.h-3 {
  height: 0.75rem;
}

.h6.mb-0 {
  font-weight: 500 !important;
}

.gif {
  position: absolute;
  left: 70%;
  top: 75%;
}

/* carousel css */
.carousel-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel {
  position: relative;
  width: 100%;
  width: 800px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 125%;
  padding: 0 12px;
  opacity: 0;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 4px 12px);
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 27s linear infinite;
  animation: carousel-animate-vertical 27s linear infinite;
}

@media only screen and (min-width: 300px) and (max-width: 1280px) {
  .carousel__item {
    width: 100%;
  }
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3s * -1);
  animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3s * 0);
  animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3s * 1);
  animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3s * 2);
  animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3s * 3);
  animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3s * 4);
  animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(3s * 5);
  animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(3s * 6);
  animation-delay: calc(3s * 6);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-3s * 2);
  animation-delay: calc(-3s * 2);
}

.carousel__item-head {
  transform: rotate(45deg);
  background-color: #d7f7fc;
  width: 70px;
  height: 70px;
  padding: 14px;
  border-radius: 4px;
  position: relative;
  margin-right: -35px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.carousel__item-head p {
  display: table-cell;
  transform: rotate(-45deg);
  font-size: small;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.carousel__item-body {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 20px;
  height: 400px;
}

@media only screen and (min-width: 300px) and (max-width: 1280px) {
  .carousel__item-body {
    height: 300px;
  }
}

.carousel__item-img {
  height: 370px;
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

@media only screen and (min-width: 300px) and (max-width: 1280px) {
  .carousel__item-img {
    height: 270px;
    max-height: 300px;
  }
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0;
    visibility: visible;
  }

  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }

  3%,
  11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0;
    visibility: visible;
  }

  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0;
    visibility: visible;
  }

  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }

  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

/* new section */
.black-section {
  padding: 100px 0;
}

@media screen and (max-width: 992px) {
  .black-section {
    padding: 0 !important;
  }
}

.black-section .row .tabs {
  all: unset;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}

.black-section .row .tabs li {
  all: unset;
  display: block;
  position: relative;
}

@media screen and (max-width: 992px) {
  .black-section .row .tabs {
    all: unset;
    margin-right: 50px;
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .black-section .row .tabs li.active::before {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    background-color: #71b85f;
    border-radius: 50%;
  }

  .black-section .row .tabs li.active::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    background-color: #71b85f;
    border-radius: 50%;
  }
}

.black-section .row .tabs li:nth-child(1) {
  align-self: flex-end;
}

.black-section .row .tabs li:nth-child(1)::before {
  left: 75%;
  bottom: -50px;
}

.black-section .row .tabs li:nth-child(1)::after {
  left: 97%;
  bottom: -81px;
}

.black-section .row .tabs li:nth-child(1) figure img {
  margin-left: auto;
}

.black-section .row .tabs li:nth-child(2) {
  align-self: flex-start;
}

.black-section .row .tabs li:nth-child(2)::before {
  right: -80px;
  top: 40%;
}

.black-section .row .tabs li:nth-child(2)::after {
  bottom: 105px;
  border-radius: 50%;
  right: -120px;
}

.black-section .row .tabs li:nth-child(2) figure img {
  margin-right: auto;
  max-width: 300px;
  width: 100%;
  margin-top: -50px;
}

@media screen and (max-width: 992px) {
  .black-section .row .tabs li:nth-child(2) figure img {
    margin-right: auto;
    max-width: 300px;
    width: 100%;
    margin-top: 0px;
  }
}

.black-section .row .tabs li:nth-child(3) {
  align-self: flex-end;
}

.black-section .row .tabs li:nth-child(3)::before {
  right: -18px;
  top: -72%;
}

.black-section .row .tabs li:nth-child(3)::after {
  top: -117px;
  border-radius: 50%;
  right: -46px;
}

.black-section .row .tabs li:nth-child(3) figure img {
  margin-left: auto;
  margin-top: -50px;
}

.black-section .row .tabs li:nth-child(3):focus {
  border: 10px solid red;
}

.black-section .row .tabs li figure {
  position: relative;
}

.black-section .row .tabs li figure img {
  display: block;
}

.black-section .row .tabs li figure::after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 4px solid #dff9d9;
  border-radius: 50%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.black-section .row .tabs li figure:hover::after {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.black-section .row .tabs.carousel-indicators li.active figure::after {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 992px) {
  .black-section .row .tabs li figure::after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 2px solid #dff9d9;
    border-radius: 50%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .black-section .row .tabs.carousel-indicators li.active figure::after {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    border: 4px solid #dff9d9;
  }
}

.black-section .row .carousel > h3 {
  font-size: 20px;
  line-height: 1.45;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  margin-bottom: 0;
}

.black-section .row .carousel h1 {
  font-size: 40px;
  line-height: 1.225;
  margin-top: 23px;
  font-weight: 700;
  margin-bottom: 0;
  color: #fff;
}

.black-section .row .carousel .carousel-indicators {
  all: unset;
  padding-top: 43px;
  display: flex;
  list-style: none;
}

.videoScreen {
  box-shadow: 0 25px 25px -5px rgba(31, 45, 61, 0.1),
    0 10px 10px -5px rgba(31, 45, 61, 0.04);
}

@media screen and (max-width: 992px) {
  .black-section .row .carousel .carousel-indicators {
    all: unset;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    list-style: none;
  }
}

.black-section .row .carousel .carousel-indicators li {
  background: #fff;
  background-clip: padding-box;
  height: 2px;
}

.black-section .row .carousel .carousel-inner .carousel-item .quote-wrapper {
  margin-top: 42px;
}

.black-section .row .carousel .carousel-inner .carousel-item .quote-wrapper p {
  font-size: 18px;
  line-height: 1.72222;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}

.black-section .row .carousel .carousel-inner .carousel-item .quote-wrapper h3 {
  color: #fff;
  font-weight: 700;
  margin-top: 37px;
  font-size: 20px;
  line-height: 1.45;
  text-transform: uppercase;
}

@media only screen and (max-width: 1200px) {
  .black-section .row .tabs {
    margin-right: 25px;
  }
}

@media screen and (max-width: 992px) {
  .black-section .row .tabs {
    margin-right: 0;
    margin-bottom: 30px;
    padding: 20px 0;
  }
}

.top-section-carousel {
  margin-top: 1.5rem !important;
}

.top-section {
  margin-top: 6rem !important;
}

@media screen and (max-width: 992px) {
  .top-section-carousel {
    margin-top: 10rem !important;
    margin-bottom: 5rem !important;
  }

  .documentation-btn {
    margin-top: 0 !important;
  }

  .top-section {
    margin-top: 0rem !important;
  }

  .top-section-header {
    margin-top: 15rem !important;
  }
}

@media screen and (max-width: 462px) {
  .documentation-btn {
    margin-top: 0.5rem !important;
  }
}

@media screen and (max-width: 768px) {
  .top-section-carousel {
    margin-top: 10rem !important;
    margin-bottom: 0rem !important;
  }

  .top-section {
    margin-top: 0rem !important;
  }

  .top-section-header {
    margin-top: 5rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .carousel__item-img {
    height: auto !important;
  }

  .carousel__item-body {
    height: auto !important;
  }

  .top-section-carousel {
    margin-top: 0 !important;
    padding-top: 0 !important;
    overflow-x: visible;
    overflow-y: clip;
  }

  .carousel {
    display: table-row;
    min-height: 200px;
  }

  /* 
  .slice.top-section {
  } */
  .Landing .slice,
  .btsp .slice {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .points.list-unstyled {
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .section-cnt {
    padding-top: 1rem !important;
  }

  .Landing .card,
  .btsp .card {
    margin-bottom: 15px;
  }

  .videoScreen {
    width: 95% !important;
    box-shadow: 0 2px 25px -5px rgba(31, 45, 61, 0.1),
      0 10px 10px -5px rgba(31, 45, 61, 0.04) !important;
  }
}
