.App {
  margin: 0;
  padding: 0;
  text-align: center;
}
h1 {
  color: gray;
}
h2 {
  font-size: 0.8em;
}
.PropName {
  font-weight: bold;
  color: #6264a7;
}
.Logo {
  font-size: 45pt;
  color: #6264a7;
}
.Error {
  color: red;
}

* ::-webkit-scrollbar-thumb {
  min-height: 60px;
}

.ui-dialog {
  overflow: scroll;
}

.collapsing {
  transition: none;
  animation: none;
}
