h1.main-heading {
  font-weight: bold;
  font-size: 64px;
}
h2.sub-heading {
  font-weight: bold;
  font-size: 52px;
}
h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
}
