.page_not_found .svg_container {
  text-align: center;
}

.page_not_found .svg_container img {
  height: 50vh;
  margin-top: 80px;
}

.page_content_wrapper {
  /* margin-top: -35px; */
  text-align: center;
}

.page_content_wrapper h1 {
  font-size: 33px;
  color: black;
}

.page_content_wrapper p {
  color: gray;
  font-size: 16px;
}

.page_content_wrapper button {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  color: #fff;
  padding-top: 0.75rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.25rem;
  padding-bottom: 0.75rem;
  background-color: #d33139;
  border: 1px #d33139 solid;
  margin-bottom: 40px;
  cursor: pointer;
}

/* .feature_container {
  width: 80%;
  margin: auto;
  height: 254px;
} */

.page_content_wrapper {
  padding-bottom: 70px;
}

.feature_container .ui-image {
  padding: 20px;
}