.table-container {
  margin: auto;
  max-width: calc(100% - 15px);
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.price-table h5 {
  margin-bottom: 0 !important;
}

.price-table thead {
  top: 82px;
  position: sticky;
}

.price-table thead th:last-of-type {
  border-top-right-radius: 10px;
}

.price-table thead th:nth-child(2) {
  border-top-left-radius: 10px;
}

.price-table thead th {
  border: none !important;
  text-align: center !important;
}

.price-table th:nth-child(2),
.price-table th:nth-child(4) {
  background-color: #e6e7e9 !important;
}

.price-table th:nth-child(3) {
  background-color: #f8d5d7 !important;
}

.price-table tbody {
  text-shadow: 0 1px 0 #fff;
  color: #2a2a2a;
  background: #fafafa;
  background-image: linear-gradient(to top, #fafbfe, #eaeaea, #fafbfe);
  border: none !important;
}

.price-table td {
  word-break: break-all;
  border: none !important;
  word-break: break-word !important;
  white-space: normal !important;
}

.price-table tbody tr:not(:first-of-type) td:not(:first-of-type) {
  word-break: break-word !important;
  white-space: normal !important;
}

.price-table tbody tr:first-of-type td:first-of-type {
  white-space: nowrap !important;
}

.price-table td:nth-child(2),
.price-table td:nth-child(4) {
  background: #efefef;
  background: rgba(153, 153, 153, 0.15);
}

.price-table td:nth-child(3) {
  background: rgb(255 117 117 / 26%) !important;
}

.price-table tbody:first-of-type tr:first-of-type td:first-child {
  border-top-left-radius: 10px !important;
}

.price-table tbody:first-of-type tr:first-of-type td {
  border-top: none !important;
}

.price-table th:not(:nth-child(1)),
.price-table td:not(:nth-child(1)) {
  text-align: center;
}

.price-table tbody:last-of-type tr:last-of-type td:nth-child(1) {
  border-bottom-left-radius: 10px;
  border-bottom: none !important;
}

.price-table tbody:last-of-type tr:last-of-type td:nth-child(4) {
  border-bottom-right-radius: 10px;
}

.price-table tbody tr:first-child {
  background-color: rgb(203 203 203 / 50%) !important;
}

.price-table tbody tr:not(:first-of-type):hover {
  background-color: rgb(214 214 214 / 60%) !important;
}

.price-block {
}

.price-unit {
  font-size: 20px;
  vertical-align: super;
}

#price-validity-sm {
  display: block !important;
  font-size: 11px !important;
}
#price-validity {
  display: none !important;
}

.price-table .icon {
  max-height: 20px;
  max-width: 20px;
  display: inline-block;
}

.price-table .icon img {
  width: 100%;
  height: auto;
}

.price-block .h5 {
  margin-bottom: 0 !important;
}

.price .fill-section-primary {
  fill: #fff !important;
}

@media screen and (max-width: 600px) {
  .price-table td,
  .price-table th {
    padding: 4px !important;
  }
  .price-block .price-unit {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .price-block .price {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  #price-validity-sm {
    font-weight: 400 !important;
  }
  .price-table .price-plan {
    font-size: 10px !important;
  }
  .price-table .feature {
    font-size: 12px !important;
  }
  .price-table .sub-feature {
    font-size: 10px !important;
  }
  .price-table tbody tr:not(:first-of-type) td:not(:first-of-type) {
    font-size: 11px !important;
  }
  .price-table thead th:last-of-type {
    border-top-right-radius: 5px;
  }
  .price-table thead th:nth-child(2) {
    border-top-left-radius: 5px;
  }
  .price-table tbody:last-of-type tr:last-of-type td:nth-child(1) {
    border-bottom-left-radius: 5px;
  }
  .price-table tbody:last-of-type tr:last-of-type td:nth-child(4) {
    border-bottom-right-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .price-block .price {
    font-size: 24px;
  }
}

@media screen and (min-width: 768px) {
  .price-table td {
    word-break: normal;
  }
  .price-block {
    flex-direction: row;
  }
  .price-block .price {
    line-height: 40px;
  }
  #price-validity {
    display: block !important;
  }
  #price-validity-sm {
    display: none !important;
  }
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 5%;
}

.creditImage {
  position: relative;
  top: 0px !important;
  right: 0px !important;
  width: 44px;
  opacity: 0.5;
}

.main-div {
  display: flex;
  gap: 12%;
}

.student-label {
  font-size: 15px;
  margin-left: 30px;
}

.badge-container {
  position: relative;
  padding: 10px;
}

.my-badge {
  position: absolute;
  border-bottom: 24px solid #4d9c40;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 7px;
  width: 109px;
  text-align: center;
  transform: rotate(-45deg);
  left: -53px;
  top: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: lighter;
}

.my-badge::before {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 11px solid transparent;
  transform: rotate(45deg);
  left: -21px;
  top: 18px;
  position: absolute;
}

.my-badge::after {
  content: "";
  border-bottom: 11px solid #2d6824;
  border-left: 11px solid transparent;
  transform: rotate(45deg);
  left: 70px;
  top: 18px;
  position: absolute;
}
