.register_wrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.register_form {
  width: 550px !important;
  padding: 60px 80px 80px;
  text-align: center;
  margin-top: 2vh;
  margin-bottom: 3vh;
  box-shadow: rgb(0 0 0 / 29%) 5px 1rem 2rem -0.075rem;
  border-radius: 10px;
}

form.register_form {
  height: auto;
}
.register_wrapper .h1 {
  font-size: 2.3em;
  color: #40428c !important;
  margin-top: 0px;
  display: flex;
  text-align: center;
}

.register_wrapper .seg2 .ui-input.ui-box {
  width: 100% !important;
}
.register_wrapper .seg2 input {
  width: 100% !important;
}

.submit_btn {
  width: 100%;
}

.submit_btn button {
  width: 100%;
  max-width: 100%;
}

.ui-form__input .ui-box {
  width: 100%;
}
.ui-form__input .ui-input__label {
  display: inline-flex !important;
}
.ui-dropdown__container {
  height: 100% !important;
  background-color: #fff !important;
  width: 100% !important;
}
.ui-dropdown__container:active {
  background-color: #fff !important;
}
.ui-dropdown__container:focus-within {
  border-bottom-color: transparent !important;
}
.ui-dropdown__container:hover {
  background-color: #fff;
}
.ui-dropdown__searchinput__input {
  padding: 0 !important;
}
.ui-dropdown__searchinput__input::placeholder {
  color: #b1bccb !important;
}

.Register button.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
}

.registration-form .input-group {
  border-radius: 0.75rem;
}

.registration-form input:disabled {
  background-color: #fff !important;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
.p-4-i {
  padding: 20px 23px !important;
}

@media only screen and (max-width: 767px) {
  .register_form {
    width: 80% !important;
    padding: 40px 50px 60px;
  }
  .register_wrapper .h1 {
    font-size: 2.1em;
  }
}
@media only screen and (max-width: 480px) {
  .register_form {
    width: 90% !important;
    padding: 20px 25px 20px;
    box-shadow: none;
  }
  .register_wrapper .h1 {
    font-size: 1.8em;
  }
}

.register_form .seg1 {
  font-weight: 600;
  font-size: 14px;
  box-shadow: none;
  height: 14px;
  padding: 0 8px;
  text-align: start;
  margin-bottom: 0.7rem !important;
}
.register_form .seg2 {
  box-shadow: none;
  padding: 0px 0px;
  background-color: transparent;
}

.Landing legend {
  font-size: 1rem !important;
  padding: 0 1rem !important;
  width: auto !important;
  margin: 0 !important;
  color: #121212;
}
.Landing fieldset {
  border: 1px solid #e2e8f0 !important;
  padding: 1rem !important;
  border-radius: 1rem !important;
  /* margin: -0.5rem !important; */
}

.Landing fieldset .form-group:last-child {
  margin-bottom: 0.1rem;
}

.google_btn {
  /* background: none !important;*/
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem !important;
  max-width: 225px;
  width: 100%;
}
.google_btn_1 {
  background: none !important;
  box-shadow: none !important;
}
.google_btn_1 > div {
  padding: 0 0 0 0 !important;
}

.google_btn > span {
  font-weight: 700 !important;
}

.close-icon {
  right: 113px;
  position: absolute;
  top: 2px;
  box-shadow: none !important;
  background: transparent !important;
}
.close-icon > div {
  display: none !important;
}

.icon-input {
  padding-right: 60px !important;
}

.form-check-input {
  position: relative !important;
}

.form-check-inline {
  display: flex !important;
  align-items: baseline !important;
  justify-content: center !important;
  margin-top: 5px;
}

.cfdi-form {
  max-height: 320px;
  /* overflow: auto; */
}

.font-16 {
  font-size: 16px;
}
