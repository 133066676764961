@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;500;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

body {
  /* margin: 5em; */
  /* margin-top: -5rem; */
  font-family: "Poppins", sans-serif;
  /* font-family: "Karla","Segoe UI", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  /* user-select: none; */
  overflow-x: clip;
}

::selection {
  color: white;
  background-color: #162c51;
}

* {
  /* font-family: "Karla", sans-serif; */
  font-family: "Poppins", sans-serif;
}

.Karla,
.Karla * {
  font-family: "Karla", sans-serif;
}

.fs16 {
  font-size: 16px;
}

.fw6 {
  font-weight: 600 !important;
}

.tcap {
  text-transform: capitalize;
}

img {
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  /* user-drag: none; */
}

.Privacy.container,
.Terms.container,
.Docs.container {
  font-family: "Karla", sans-serif;
  color: #212529;
  margin-inline: 2.5rem;
}

.Privacy h1 {
  margin-top: 1.5em;
  text-decoration: underline;
}

.Privacy h2,
.Privacy h3,
.docs .h3 {
  font-size: 1.75em;
}

@media only screen and (min-width: 900px) {
  .Privacy.container,
  .Terms.container,
  .Docs.container {
    margin: 50px 0;
    margin-inline: 10rem;
  }
}

.cur-pointer {
  cursor: pointer;
}

#navbar-main {
  transition: 0s;
}

body.modal-open #navbar-main {
  padding-right: 2rem;
}

body.modal-open {
  overflow-y: hidden;
}

.font-1 {
  font-size: 1rem;
}

.hindi {
  font-family: "Noto Sans Devanagari", sans-serif !important;
}

.hindi p {
  font-size: 1.2rem;
}
