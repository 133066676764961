.Cta {
  height: 620px;
  background-color: #f5f5f5;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('assets/img/bg.png');
  background-size: 16px;
}

.Cta h1 {
  font-size: 52px;
  font-weight: 600;
}