.unsubscribe-wrapper {
  height: 100vh;
  background-color: #f5f5f5;
  width: 100%;
}

.unsubscribe-wrapper .unsubscribe-header {
  height: 70%;
  width: 100%;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unsubscribe-wrapper .unsubscribe-header h1 {
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  color: #fff;
  text-wrap: wrap;
  display: flex;
  justify-content: left;
  text-align: left;
}

.unsubscribe-header .unsubscribe-header-logo {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  position: relative;
  bottom: 33px;
}

.title {
  width: 50%;
  margin-right: 0px;
}
.unsubscribe-wrapper .unsubscribe-header h6 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  padding: 0;
  letter-spacing: 1px;
  color: #fff;
  text-wrap: wrap;
  display: flex;
  justify-content: left;
  text-align: left;
}

.unsubscribe-wrapper .unsubscribe-footer {
  height: 30%;
  /* background-color: #b62d34; */
  width: 100%;
}

.acv-logo {
  margin-bottom: 70px;
}

.unsubscribe-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  row-gap: 32px;
  margin-bottom: 6px;
}

.unsubscribe-form .unsubscribe-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 40px;
  font-size: 15px;
  border: none;
  border-radius: 2px;
  width: 300px;
  padding-left: 10px;
}

.unsubscribe-form .button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b62d34;
  color: #fff;
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.unsubscribe-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

@media (max-width: 917px) {
  .unsubscribe-header-logo {
    flex-direction: column;
    gap: 40px;
  }
  .title {
    width: auto;
    /* padding-left: 160px;
    padding-right: 160px; */
  }
  .unsubscribe-form {
    width: 100%;
  }
  /* .navbar-logo {
    padding-bottom: 0px;
    margin-top: 10%;
  } */
  .unsubscribe-wrapper .unsubscribe-header {
    height: 100%;
  }
}
@media (max-width: 301px) {
  .unsubscribe-header-logo {
    flex-direction: column;
    gap: 40px;
  }
  .title {
    width: auto;
  }
  .unsubscribe-form {
    width: 100%;
  }
  .navbar-logo {
    margin-top: 30%;
  }
  .unsubscribe-wrapper .unsubscribe-header {
    height: 100%;
  }
}

@media (max-width: 917px) and (min-width: 585px) {
  .unsubscribe-header-logo {
    flex-direction: column;
    gap: 40px;
  }
  .title {
    width: auto;
    padding-left: 100px;
    padding-right: 100px;
  }
  .unsubscribe-form {
    width: 100%;
  }
  /* .navbar-logo {
    padding-bottom: 0px;
    margin-top: 10%;
  } */
  .unsubscribe-wrapper .unsubscribe-header {
    height: 100%;
  }
}
