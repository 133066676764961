.text-muted {
  color: #6b7b8b;
}

.text-brand {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-brand-rgb), var(--bs-text-opacity)) !important;
}

.bg-brand {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-brand-rgb), var(--bs-bg-opacity)) !important;
}

.bg-brand {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-brand-rgb), var(--bs-bg-opacity)) !important;
}

.rounded-5 {
  border-radius: 0.5rem;
}

.btn-danger:hover {
  background-color: #cb3948;
}

.fw-600 {
  font-weight: 600 !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.rounded-5 {
  border-radius: 8px !important;
}