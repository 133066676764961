.space-x {
  margin-top: 5.5rem;
  margin-bottom: 7.5rem;
}

.space-x2 {
  padding-top: 8.5rem;
  margin-bottom: 7.5rem;
}

@media (max-width: 1200px) {
  .space-x2 {
    margin-bottom: 3rem;
  }
}
