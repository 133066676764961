.py-x {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.block {
  max-width: 60%;
  opacity: 0;
  width: 0;
}

.block.active {
  opacity: 1;
}

.bg-clr-4 {
  background-color: #bcf6f9;
}

.bg-clr-5 {
  background-color: #b1b8f8;
}

.bg-clr-6 {
  background-color: #f9ecbc;
}

.scroller-container {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 88px;
  padding-bottom: 88px;
}

@media (max-width: 991px) {
  .scroller-container {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .scroller-container {
    display: block;
  }

  .scroller-container-mobile {
    display: none !important;
  }

  .Landing .col-imgs img {
    /* max-width: 56vw; */
    /* max-width: 500px !important; */
    max-width: 36rem !important;
  }

  .scroller {
    padding-bottom: 6rem;
  }

  .scroller img {
    max-width: 45vw;
  }

  .Landing .col-imgs {
    width: 50% !important;
    max-width: 670px !important;
    height: 100vh;
    max-height: 500px;
    display: flex;
    align-items: center;
    position: sticky !important;
    top: calc(50% - 250px);
    /* top: 200px; */
  }

  .col-blocks {
    position: relative;
    width: 40%;
    height: 100%;
  }
}

@media only screen and (max-width: 1204px) and (min-width: 300px) {
  .Landing .col-imgs img {
    max-width: 30rem !important;
  }
}

.section {
  position: relative;
  height: 100vh;
  max-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.section-wrapper {
  max-width: 400px;
  opacity: 0.3;
  transition: opacity 250ms ease-in-out 0s;
}

.active {
  max-width: 400px;
  opacity: 1;
  transition: opacity 250ms ease-in-out 0s;
}

.cmp-section {
  padding-top: 5.5rem;
  padding-bottom: 7.5rem;
  background-color: white;
}

.scroller-container p.text-muted.font-italic {
  font-family: "Karla", "Poppins", sans-serif;
}

.scroller-container h5.font-weight-light {
  font-size: 1.1rem;
}

.scroller-container h3 {
  /* text-decoration: underline #d23139; */
  /* color: #121212; */
}
