.School {
  width: 50%;
  padding-top: 30px;
}

.schoolLogo {
  max-height: 40px;
  max-width: fit-content;
  border-radius: 2px;
}

.School button {
  border-radius: 5px !important;
}
