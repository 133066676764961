.whiteboard {
  overflow-x: clip;
  user-select: none;
}


.hero h1,
.hero h2,
.hero h3,
.hero h4,
.hero h5,
.hero h6,
.hero p,
.hero button {
  z-index: 2 !important;
  position: relative;
  pointer-events: none;
}

.hero img {
  z-index: 2 !important;
  /* position: relative; */
  pointer-events: none;
}

canvas {
  cursor: url("assets/img/pcursor.png") 3 27, pointer !important;
  background-image: url("assets/img/bg.png");
  background-size: 16px;
  position: absolute;
  top: 62px;
  left: 0;
}

.whiteboard .fill-section-primary {
  fill: #f5f5f5;
}

@media (max-width: 992px) {
  canvas {
    pointer-events: none;
  }
}